.modal {
  font-size: 12px;
  background-color: white;
  border-radius: 15px;
}
.modal > .header {
  width: 100%;
  color: #6A3FD1;
  font-weight: 500;
  border-bottom: 1px solid gray;
  font-size: 1.8em;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  margin-left: 2%;
  font-size: medium;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}











.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 50%;
  padding: 5px;
  border-radius: 15px;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}



@media (max-device-width:480px){
  .popup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    width: 80%;
    padding: 5px;
    border-radius: 15px;
  }
}