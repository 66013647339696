
.caser{
    text-align: center;

}
  
.checkb{
  width: 120%;
  font-size: .9em;
 
}
.input_isd{
    width:5.5rem;
    padding-left: .5em;
    margin-top: 1em;
   
    height: 30px;
    background: #FFFFFF;
    opacity: 0.4;
    border : 1px solid  #767676;
    border-radius: 6px;
    border-radius: 6px;
    font-size: medium;
    color: black;
}

.input_phone{
    width:10rem;
    margin-left: .7em;
    margin-top: 1em;
    padding-left: 1em;
    height: 30px;
    background: #FFFFFF;
    opacity: 0.4;
    border : 1px solid  #767676;
    border-radius: 6px;
    border-radius: 6px;
    font-size: medium;
    color: black;   
}


.checkR{
    width: 100%;
    font-size: .9em;
    margin-left: 19%;
}


  #conbanner_p {
      position: absolute;
      top: 72%;
      margin-left: 3%;
    width: 75%;
    font-family: "Poppins";
    font-weight: 800;
    line-height: 2em;
    font-size:1.2em;
    color: #ffffff;
  }

  .con_flex{
      margin: 4%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
  }

  .con_flex img{
      margin-left: 35%;
  }

  .con_flex h2{
      text-align: center;
  }
  .indi_p{
      text-align: center;
      height: 4.5rem;
  }

  .need{
      margin-top: 1.5%;
      text-align: center;
      color: #0F2492;
      cursor: pointer;
  }

  .need h3{
    cursor: pointer;
  }
  .need hr{
      width: 50%;
      
border: 1.5px solid #0F2492;
  }

  #ceoh1{
    font-family: 'Poppins';
  }
  #form_bg{
      background-color:  rgba(249, 249, 249, 0.7);
      border-radius: 10px;
      width: 100%;
      padding-bottom: 1%;
      padding-top: 1%;
  }

  #form_bg h1{
     
      padding-bottom: 1%;
      text-align: center;
  }

  .form{
      background-color: white;
      width: 85%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 11px;
/* padding-left: 5%;
padding-right: 5%; */
padding-top: 1%;
margin-bottom: 5%;
margin-left: auto;
margin-right: auto;

  }

  #conrow{
      margin-left: 4%;
      margin-right: 4%;
      display: flex;
      flex-direction: row;
      margin-top: 3%;
      justify-content: space-between;
  }

#input{
margin-top: 1em;
margin-left: 0rem;
width: 15rem;
height: 30px;
background: #FFFFFF;
opacity: 0.4;
border: 1px solid  #767676;
border-radius: 6px;
font-size: medium;
color: black;
}

.input_2{
    padding-left: 2.8em;
margin-top: 1em;
width: 15.2rem;
height: 30px;
background: #FFFFFF;
opacity: 0.4;
border : 1px solid  #767676;
border-radius: 6px;
border-radius: 6px;
font-size: medium;
color: black;
}

#red_star{
    color: #E83800;
}

#mail_grey{
    position: relative;
    top: 3.1rem;
width: 12%;
height: 33px;
background: rgba(196, 196, 196, 0.5);
border-radius: 6px 0px 0px 6px;
align-items: center;
}

#mail_img{
    padding: 20%;
}

#combined_ip{
    margin-top: -10%;
}

.text_area{
    margin-top: 1em;
    width: 70%;
    padding: 1%;
 
    background: #FFFFFF;
    opacity: 0.4;
    border: 1px solid #767676;
    border-radius: 6px;
    font-size: medium;
    color: black;
}

label{
margin-left: auto;
margin-right: auto;
background: #F1F1F1;
border: 1px solid #BFC7EC;
border-radius: 6px;
width: 9rem;
text-align: center;
color: black;
height: 2rem;
padding: .3%;
}

#conrow_last{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 3%;
    margin-left: 4%;
}

#upload_img{
    margin-top: 2%;
}

#form_bottom_grey{
    width: 94%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 11px;
    background-color: #f0f0f0;
    margin-top: 4%;
    align-items: center;
    justify-content: center;
    padding: 3%;
    text-align: center;
}

#send_btn{
    width: 6rem;
    background-color: #2961FF;
    color: white;
    border: none;
    font-size: medium;
    margin: auto auto;
    padding: 1% 2% 1% 2%;
    border-radius: 10px;
}

.em_blue_bg{
    background: #203797;
    
    padding: 3%;
    color: white;
   text-align: center;
}

.em_blue_bg p{
    font-size: large;
}
#emailceo{
    margin-top:2%;
color: white;
padding: 1.3%;
border-color: floralwhite;
font-size: medium;
background: #203797;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;

}

.ceopage{
    background: linear-gradient(270deg, #57AEDE 10.05%, #6A3FD1 34.85%, #57AEDE 102.54%);
 height: 100vh;
 display: flex;
 flex-direction: row;

}

.ceoform{
  height: 84%;
  width: 40%;
  margin-top: 3%;
    margin-left: auto;
  margin-right: auto;
    background-color: white;
    border-radius: 2%;
    align-items: center;
    box-shadow: #bcf6ff 8px 10px 10px;
}
.ceoform h2{
    text-align: center;
}
.fields{
    margin-left: 20%;
  margin-right: auto;

}

.form-control{
    width: 70%;
    height: 1.5rem;
    border-radius: 8px;
    border-color: #d4d4d4;
    margin-bottom: 5%;
    padding: 1%;
}

#message{
    border-radius: 8px;
    width: 70%;
    padding: 1%;
}

#submit_btn{

    padding: 2%;
    border-radius: 10px;
    background-color: #6a3fd1;
    color: white;
    cursor: pointer;
    border: none;
    margin-left: 37%;
    margin-top: 3%;
    margin-right: auto;
  
}

#submit_btn:hover{
    background: linear-gradient(270deg, #57AEDE 10.05%, #202de9 34.85%, #662DE4 102.54%);

}

.radio_flex{
    margin-left: -230%;
}
#sub{
    margin-left:-220%;
    margin-bottom: 3%;
}
















@media only screen and (max-device-width:480px){

    #conbanner_h1{
        position: absolute;
        top: 19%;
        float: left;
        width: 66%;
        font-size: var(--space-s);
        line-height: 1.3em;
    }

    td{
        height: max(20px,auto);
    }
  #conbanner_p {
    position: absolute;
    top: 34%;
    margin-left: 3%;
    font-size: small;
    line-height: 1.4em;
  width: 80%;
  }

  
  .con_flex{
    margin-top: 4%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}


.con_flex h2{
    text-align: center;
    font-size: 1.4em;
    margin-top: 2%;
}
.indi{
    margin-top: 6%;
}

.indi_p{
    text-align: center;
    height: 4.5rem;
    margin-top: 4%;
}

.need{
    margin-top: 1.5%;
    text-align: center;
    color: #0F2492;
    font-size: 1em;
}

.need hr{
    width: 80px;
    margin-bottom: 16%;
}

.form{
    background-color: white;
    width: 98%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 11px;
/* padding-left: 5%;
padding-right: 5%; */
padding-top: 1%;
margin-bottom: 2%;
margin-left: auto;
margin-right: auto;

}

#conrow{
    margin-left: 4%;
    margin-right: 4%;
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    justify-content: space-around;
}

.checkb{
    font-size: 10px;
}

#input{
margin-bottom: 1.4em;
height: 30px;
background: #FFFFFF;
opacity: 0.4;
border: 1px solid  #767676;
border-radius: 6px;
font-size: medium;
color: black;
width:21rem;
}
.input option{
    font-size: small;
  
}


.input_2{
  padding-left: 2.8em;
margin-bottom: 1em;
width: 18rem;
height: 30px;
background: #FFFFFF;
opacity: 0.4;
border : 1px solid  #767676;
border-radius: 6px;
border-radius: 6px;
font-size: medium;
color: black;
}
.text_area{
margin-top: 1em;
width: 95%;
}


#send_btn{
    width: 6rem;
    background-color: #2961FF;
    color: white;
    border: none;
    font-size: large;
    padding: 1% 2% 1% 2%;
    border-radius: 10px;
    margin-left: 35%;
    margin-right: 35%;
    align-self: center;
}
.em_blue_bg{
    background: #203797;    
    padding: 3%;
    color: white;
   text-align: center;
   font-size: medium;
   margin-bottom: 6%;
}

.em_blue_bg p{
    text-align: center;
    font-size: medium;
}
.checkb{
  
    font-size: .9em;
   
  }
  .checkR{
   margin-left: 0;
   margin-top: 2%;
 
  }
  
  .caser{
    text-align: center;
    margin-top: 5%;
}

.input_phone{
    width:60%;
}

#ceoh1{
    font-size: 1.5em;
  }
  
.radio_flex{
    margin-left: 0%;
    color: #6e6e6e;
}
#sub{
    margin-left:0%;
    margin-top: 4%;
    margin-bottom: 3%;
}


}








@media (min-device-width:768px)and (max-device-width:1024px){
    .banner_tag{
        margin-top: -8em;
    }
    #conbanner_p{
        margin-top: -8em;
        font-size: 1em;
        font-weight: 500;
    }
    #conbanner_h1{
        margin-top: -3.5em;
        width: 88%;
        font-size: 2.5em;
    }
    .con_flex img{
        margin-left: 35%;
        width: 20%;
        height: 20%;
    }
    .indi h2{
        font-size: 1em;
    }
    #sales-img{
        margin-top: 0px;
        margin-left: 38%;
    }
    #mail-img{
        width: 28%;
    }
    .indi_p{
        font-size: .7em;
    }
    .need{
        font-size: .8em;
    }
    .need hr{
        margin: 2% auto;

    }
    .con_flex{
        justify-content: space-between;
    }
    .em_blue_bg h1{
        font-size: 1.5em;
    }
    .em_blue_bg  p{
        font-size: 1em;
    }


    /* FORM */

    
.form{
    background-color: white;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 11px;
padding-top: 2%;
margin-bottom: 5%;
margin-left: auto;
margin-right: auto;

}

#conrow{
    margin-left: 4%;
    margin-right: 4%;
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    justify-content: space-around;
}

.checkb{
    font-size: 10px;
}

.input{
margin-bottom: 1em;
height: 30px;
background: #FFFFFF;
opacity: 0.4;
border: 1px solid  #767676;
border-radius: 6px;
font-size: medium;
color: black;
width:30rem;
padding-left: 1%;
}
.input option{
    font-size: small;
  
}


.input_2{
  padding-left: 2.8em;
margin-bottom: 1em;
width: 27.5rem;
height: 30px;
}
.text_area{
margin-top: 1em;
width: 95%;
}


#send_btn{
    width: 6rem;
    background-color: #2961FF;
    color: white;
    border: none;
    font-size: medium;
    padding: 1% 2% 1% 2%;
    border-radius: 10px;
    
}
.em_blue_bg{
    background: #203797;    
    padding: 3%;
    color: white;
   text-align: center;
   font-size: medium;
   margin-bottom: 6%;
}

.em_blue_bg p{
    text-align: center;
    font-size: medium;
}
.checkb{
  
    font-size: .9em;
   
  }
  .checkR{
   margin-left: 0;
   margin-top: 2%;
 
  }
  
  .caser{
    text-align: center;
    margin-top: 5%;
}

.input_phone{
    width:40%;
}

#ceoh1{
    font-size: 1.5em;
  }
  
.radio_flex{
    margin-left: 0%;
    color: #6e6e6e;
}
#sub{
    margin-left:0%;
    margin-top: 4%;
    margin-bottom: 3%;
}
#mail_grey{
    width: 6%;
    height: 30px;
}
#combined_ip{
    margin-top: -5%;
}
}


table { 
	width: 80%; 
	border-collapse: collapse; 
	margin:50px auto;
    border-radius: 1em;
	}

/* Zebra striping */
tr:nth-of-type(odd) { 
	background: rgb(252, 252, 252); 
   
	}

th { 
	background: rgb(209, 209, 209); 
	color: black; 
	font-weight: bold; 
    
	}

td, th { 
	padding: 10px; 
	border: 1px solid #ccc; 
	text-align: left; 
	font-size: 18px;
	}

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	table { 
	  	width: 100%; 
        border-radius: 1em;
	}

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { border: 1px solid #ccc; }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
	}

	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
		/* Label the data */
		content: attr(data-column);

		color: #000;
		font-weight: bold;
	}

}
















































































































































