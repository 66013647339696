.react-tabs {
    -webkit-tap-highlight-color: transparent;
  }
  
  .react-tabs__tab-list {
    margin: 0 0 10px;
    padding: 0;
  }
  
  .react-tabs__tab {
    box-sizing: border-box;
    display: inline-block;
    border: 1px solid transparent;
    position: relative;
    list-style: none;
    border-radius: 10px 0 10px 0;
    padding: 6px 12px;
    cursor: pointer;
    height: 40px;
    width: 50%;
    background: #eeeeee;
    color: #454545;
  }
  
  .react-tabs__tab:not(.react-tabs__tab--selected):hover {
    background: #d8d8d8;
    width: 50%;
  }
  
  .react-tabs__tab--selected {
    background: #c3c3c3;
    color: black;
    font-weight: 500;
    width: 50%;
  }
  
  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }
  
  .react-tabs__tab:focus {
    box-shadow: 0px 0px 1px 3px rgba(150, 35, 195, 0.6);
    outline: none;
  }
  
  .react-tabs__tab-panel {
    display: none;
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
  }
  
  
  .prod-right{
    width: 60%;
    margin-left: 3%;
    margin-top: 2%;
    margin-right: 6%;
  }

  .prod-right li{
    font-size: large;
    margin-top: 1%;
  }

  .flexrow{
    display: flex;
    flex-direction: row;
  }


  .prod-right p{
    font-size: large;
    font-weight: 500;
    line-height: 30px;
  }

  .prod-right h2{
    margin-top: 5%;
    font-size: large;
    font-weight: 500;
    float: left;
  }


.mac-img{
    transform: scale(.9);
    margin-top: 1rem;
}

#prod-left{
    margin: 2% 4% 4% 4%;
    width: 25%;
}

#prod-left h2{
    margin-left: 3%;
    margin-bottom: 0px;
}

.flexrow >:nth-child(2){
    margin: 2rem;
    height: 3rem;
}

.cart-btn{
    width: 18%;
    padding: 1%;
    border: none;
    cursor: pointer;
    margin-top: 5rem;
    margin-left: -20rem;
    font-size: larger;
    font-weight: 500;
    float: left;
    border-radius: 6px;
    color: white;
    background-color: #6A3FD1;
}

#price{
    color: #6A3FD1;
    text-align: center;
    font-size: 1.6rem;
}

#gst{
  color: #6A3FD1;
  text-align: center;
  font-size: 1rem;
  margin-top: -4%;
}


.spares-line{
  color: #454545;
  margin-top: 3%;
  margin-bottom: 3%;
  width: 85%;
}

#spares-title{
  margin-left: 4%;
}

.spare-card{
  margin: 2% 4% 2% 4%;
  height: 17rem;
  width:18rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
}

.spare-card h4{
  margin: 1em 1em 0 1em;
  text-align: center;
}

.card-img{
  width: 80%;
  height: 56%;
  padding: 1rem 1rem 0 1rem;
}

#card-price{
  color: #6A3FD1;
  margin-top: 0.5em;
  margin-bottom: 0.3em;
  text-align: center;
}

.cards-row{
  display: flex;
  flex-direction: row;
}

.quantity{
  display: flex;
  flex-direction: row;
}

#qty{
  margin-top: 1.2rem;
  margin-left: 2rem;
}




@media (max-device-width:480px){
  .flexrow{
    display: flex;
    flex-direction: column;
  }
  #prod-left h2{
    margin-top: 8%;
    width: 20rem;
  }
  #prod-left {
    display: grid;
    place-items: center;
  }
  .prod-right{
    width: 90%;
  }
  .quantity{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  #qty{
   margin: 0;
  }
  #future_avl{
    font-size: 2.3rem;
  }
  .cart-btn{
    font-size: large;
    width: auto;
    padding: 2%;
    margin-bottom: 3%;
  }
  .cards-row{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .spares-line{
    color: #454545;
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 85%;
  }
  .spare-card{
    margin-top: 1.5rem;
  }
}