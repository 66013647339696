.rect-container{
    height: 60vh;
    width: 60rem;
    background-color: #fbfbfb;
    border-radius: 10px ;
    position: absolute;
    top: 25%;
    left: 22%;
    margin: -50px 0 0 -50px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#background{
    background-image:url("../assets/login/gradient-bg.png");   
    background-size: cover;
    height: 100vh;
    width: 100%;
  background-repeat:no-repeat; 
  margin: 0;
}

#pradco-login{
    transform: scale(0.9);
    margin: 2% 0% 0% 13%;
}

#we-innovate{
    margin-left: 23%;
    margin-top: 0;
}

.login-container{
    height: 76vh;
    width: 20rem;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-left: 60%;
    margin-top: -51vh;
}

#login-h{
    padding-top: 5%;
    margin-left: 6%;
}

.login-btn{
    width: 40%;
    height: 30px;
    border: none;
    cursor: pointer;
    margin-top: 50vh;
    display: block;
    margin: auto;
    border-radius: 6px;
    color: white;
    background-color: #6A3FD1;
}

.login-pts{
    margin-top: 4%;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    line-height: 30px;
}

.input{
    box-sizing: border-box;
    width: 80%;
    height: 2rem;
    margin-left: 6%;
opacity: 0.4;
border: 1px solid #9D9D9D;
border-radius: 6px;
}

#onestop{
    text-align: center;
     margin-left: -30rem;
}



@media (max-device-width:480px){
    .login-pts{
        display: none;
    }
    
    #onestop{
        text-align: center;
         margin-left: -0rem;
         font-size: .9rem;
    }

    #pradco-login{
        width: 65%;
        margin-left: 18%;
    }

    #we-innovate{
        text-align: center;
        width: 68%;
    }

    .login-container p{
        font-size: .85rem;
    }
    
    #background{
        background-size: cover;
        margin: -5% -5% ;
        
        width: 102.8vw;
    }

    .rect-container{
        width: 94%;
        margin-left: -18.5%;
        margin-top: -40%;
        height: auto;
        padding-bottom: 7%;
    }

    .login-container{
        width: 90%;
        margin-top: 12%;
        margin-left: 5%;
        height: auto;
        padding-bottom: 6%;
    }

    #login-h{
        font-size: large;
    }
}